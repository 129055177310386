import { defineNuxtPlugin } from "#app";
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;

  const config = useRuntimeConfig();
  console.info("version", config.public.name, config.public.release);
  Sentry.init({
    app: vueApp,
    dsn: "https://6f5e29d6b6664394a4483371fdfd6eb4@o1196301.ingest.sentry.io/4505478294536192",
    environment: process.dev ? "development" : "production",
    release: config.public.name + "@" + config.public.release,
    logErrors: true,
    denyUrls: [/googlesyndication\.com\//, /static\.doubleclick\.net\//],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          nuxtApp.$router
        ),

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/climatespy\.com\/.*/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!

    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
});
