import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as _91year_93dPdZGkuFuRMeta } from "/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/[year].vue?macro=true";
import { default as index3FERVdXZcxMeta } from "/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/index.vue?macro=true";
import { default as SelectMonthYearX4IM2JcbgtMeta } from "/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/SelectMonthYear.vue?macro=true";
import { default as indexyxD9QxKL51Meta } from "/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/index.vue?macro=true";
import { default as fetchmepLZwTBxHAeMeta } from "/opt/buildhome/repo/pages/fetchme.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93fKWKWSTdtTMeta } from "/opt/buildhome/repo/pages/places/dir/[...slug].vue?macro=true";
import { default as _91state_93I5RXpKladcMeta } from "/opt/buildhome/repo/pages/places/dir/united-states/[state].vue?macro=true";
export default [
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about___en",
    path: aboutcGvMViY0SnMeta?.path ?? "/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about___de",
    path: aboutcGvMViY0SnMeta?.path ?? "/de/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about___es",
    path: aboutcGvMViY0SnMeta?.path ?? "/es/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about___fr",
    path: aboutcGvMViY0SnMeta?.path ?? "/fr/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about___zh",
    path: aboutcGvMViY0SnMeta?.path ?? "/zh/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91year_93dPdZGkuFuRMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month-year___en",
    path: _91year_93dPdZGkuFuRMeta?.path ?? "/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()/:year()",
    meta: _91year_93dPdZGkuFuRMeta || {},
    alias: _91year_93dPdZGkuFuRMeta?.alias || [],
    redirect: _91year_93dPdZGkuFuRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/[year].vue").then(m => m.default || m)
  },
  {
    name: _91year_93dPdZGkuFuRMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month-year___de",
    path: _91year_93dPdZGkuFuRMeta?.path ?? "/de/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()/:year()",
    meta: _91year_93dPdZGkuFuRMeta || {},
    alias: _91year_93dPdZGkuFuRMeta?.alias || [],
    redirect: _91year_93dPdZGkuFuRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/[year].vue").then(m => m.default || m)
  },
  {
    name: _91year_93dPdZGkuFuRMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month-year___es",
    path: _91year_93dPdZGkuFuRMeta?.path ?? "/es/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()/:year()",
    meta: _91year_93dPdZGkuFuRMeta || {},
    alias: _91year_93dPdZGkuFuRMeta?.alias || [],
    redirect: _91year_93dPdZGkuFuRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/[year].vue").then(m => m.default || m)
  },
  {
    name: _91year_93dPdZGkuFuRMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month-year___fr",
    path: _91year_93dPdZGkuFuRMeta?.path ?? "/fr/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()/:year()",
    meta: _91year_93dPdZGkuFuRMeta || {},
    alias: _91year_93dPdZGkuFuRMeta?.alias || [],
    redirect: _91year_93dPdZGkuFuRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/[year].vue").then(m => m.default || m)
  },
  {
    name: _91year_93dPdZGkuFuRMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month-year___zh",
    path: _91year_93dPdZGkuFuRMeta?.path ?? "/zh/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()/:year()",
    meta: _91year_93dPdZGkuFuRMeta || {},
    alias: _91year_93dPdZGkuFuRMeta?.alias || [],
    redirect: _91year_93dPdZGkuFuRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/[year].vue").then(m => m.default || m)
  },
  {
    name: index3FERVdXZcxMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month___en",
    path: index3FERVdXZcxMeta?.path ?? "/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()",
    meta: index3FERVdXZcxMeta || {},
    alias: index3FERVdXZcxMeta?.alias || [],
    redirect: index3FERVdXZcxMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/index.vue").then(m => m.default || m)
  },
  {
    name: index3FERVdXZcxMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month___de",
    path: index3FERVdXZcxMeta?.path ?? "/de/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()",
    meta: index3FERVdXZcxMeta || {},
    alias: index3FERVdXZcxMeta?.alias || [],
    redirect: index3FERVdXZcxMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/index.vue").then(m => m.default || m)
  },
  {
    name: index3FERVdXZcxMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month___es",
    path: index3FERVdXZcxMeta?.path ?? "/es/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()",
    meta: index3FERVdXZcxMeta || {},
    alias: index3FERVdXZcxMeta?.alias || [],
    redirect: index3FERVdXZcxMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/index.vue").then(m => m.default || m)
  },
  {
    name: index3FERVdXZcxMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month___fr",
    path: index3FERVdXZcxMeta?.path ?? "/fr/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()",
    meta: index3FERVdXZcxMeta || {},
    alias: index3FERVdXZcxMeta?.alias || [],
    redirect: index3FERVdXZcxMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/index.vue").then(m => m.default || m)
  },
  {
    name: index3FERVdXZcxMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month___zh",
    path: index3FERVdXZcxMeta?.path ?? "/zh/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()",
    meta: index3FERVdXZcxMeta || {},
    alias: index3FERVdXZcxMeta?.alias || [],
    redirect: index3FERVdXZcxMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/index.vue").then(m => m.default || m)
  },
  {
    name: SelectMonthYearX4IM2JcbgtMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month-SelectMonthYear___en",
    path: SelectMonthYearX4IM2JcbgtMeta?.path ?? "/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()/SelectMonthYear",
    meta: SelectMonthYearX4IM2JcbgtMeta || {},
    alias: SelectMonthYearX4IM2JcbgtMeta?.alias || [],
    redirect: SelectMonthYearX4IM2JcbgtMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/SelectMonthYear.vue").then(m => m.default || m)
  },
  {
    name: SelectMonthYearX4IM2JcbgtMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month-SelectMonthYear___de",
    path: SelectMonthYearX4IM2JcbgtMeta?.path ?? "/de/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()/SelectMonthYear",
    meta: SelectMonthYearX4IM2JcbgtMeta || {},
    alias: SelectMonthYearX4IM2JcbgtMeta?.alias || [],
    redirect: SelectMonthYearX4IM2JcbgtMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/SelectMonthYear.vue").then(m => m.default || m)
  },
  {
    name: SelectMonthYearX4IM2JcbgtMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month-SelectMonthYear___es",
    path: SelectMonthYearX4IM2JcbgtMeta?.path ?? "/es/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()/SelectMonthYear",
    meta: SelectMonthYearX4IM2JcbgtMeta || {},
    alias: SelectMonthYearX4IM2JcbgtMeta?.alias || [],
    redirect: SelectMonthYearX4IM2JcbgtMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/SelectMonthYear.vue").then(m => m.default || m)
  },
  {
    name: SelectMonthYearX4IM2JcbgtMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month-SelectMonthYear___fr",
    path: SelectMonthYearX4IM2JcbgtMeta?.path ?? "/fr/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()/SelectMonthYear",
    meta: SelectMonthYearX4IM2JcbgtMeta || {},
    alias: SelectMonthYearX4IM2JcbgtMeta?.alias || [],
    redirect: SelectMonthYearX4IM2JcbgtMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/SelectMonthYear.vue").then(m => m.default || m)
  },
  {
    name: SelectMonthYearX4IM2JcbgtMeta?.name ?? "climate-summary-united-states-stateUrl-placeUrl-month-SelectMonthYear___zh",
    path: SelectMonthYearX4IM2JcbgtMeta?.path ?? "/zh/climate/summary/united-states/:stateUrl()/:placeUrl()/:month()/SelectMonthYear",
    meta: SelectMonthYearX4IM2JcbgtMeta || {},
    alias: SelectMonthYearX4IM2JcbgtMeta?.alias || [],
    redirect: SelectMonthYearX4IM2JcbgtMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/[month]/SelectMonthYear.vue").then(m => m.default || m)
  },
  {
    name: indexyxD9QxKL51Meta?.name ?? "climate-summary-united-states-stateUrl-placeUrl___en",
    path: indexyxD9QxKL51Meta?.path ?? "/climate/summary/united-states/:stateUrl()/:placeUrl()",
    meta: indexyxD9QxKL51Meta || {},
    alias: indexyxD9QxKL51Meta?.alias || [],
    redirect: indexyxD9QxKL51Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyxD9QxKL51Meta?.name ?? "climate-summary-united-states-stateUrl-placeUrl___de",
    path: indexyxD9QxKL51Meta?.path ?? "/de/climate/summary/united-states/:stateUrl()/:placeUrl()",
    meta: indexyxD9QxKL51Meta || {},
    alias: indexyxD9QxKL51Meta?.alias || [],
    redirect: indexyxD9QxKL51Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyxD9QxKL51Meta?.name ?? "climate-summary-united-states-stateUrl-placeUrl___es",
    path: indexyxD9QxKL51Meta?.path ?? "/es/climate/summary/united-states/:stateUrl()/:placeUrl()",
    meta: indexyxD9QxKL51Meta || {},
    alias: indexyxD9QxKL51Meta?.alias || [],
    redirect: indexyxD9QxKL51Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyxD9QxKL51Meta?.name ?? "climate-summary-united-states-stateUrl-placeUrl___fr",
    path: indexyxD9QxKL51Meta?.path ?? "/fr/climate/summary/united-states/:stateUrl()/:placeUrl()",
    meta: indexyxD9QxKL51Meta || {},
    alias: indexyxD9QxKL51Meta?.alias || [],
    redirect: indexyxD9QxKL51Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyxD9QxKL51Meta?.name ?? "climate-summary-united-states-stateUrl-placeUrl___zh",
    path: indexyxD9QxKL51Meta?.path ?? "/zh/climate/summary/united-states/:stateUrl()/:placeUrl()",
    meta: indexyxD9QxKL51Meta || {},
    alias: indexyxD9QxKL51Meta?.alias || [],
    redirect: indexyxD9QxKL51Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/climate/summary/united-states/[stateUrl]/[placeUrl]/index.vue").then(m => m.default || m)
  },
  {
    name: fetchmepLZwTBxHAeMeta?.name ?? "fetchme___en",
    path: fetchmepLZwTBxHAeMeta?.path ?? "/fetchme",
    meta: fetchmepLZwTBxHAeMeta || {},
    alias: fetchmepLZwTBxHAeMeta?.alias || [],
    redirect: fetchmepLZwTBxHAeMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/fetchme.vue").then(m => m.default || m)
  },
  {
    name: fetchmepLZwTBxHAeMeta?.name ?? "fetchme___de",
    path: fetchmepLZwTBxHAeMeta?.path ?? "/de/fetchme",
    meta: fetchmepLZwTBxHAeMeta || {},
    alias: fetchmepLZwTBxHAeMeta?.alias || [],
    redirect: fetchmepLZwTBxHAeMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/fetchme.vue").then(m => m.default || m)
  },
  {
    name: fetchmepLZwTBxHAeMeta?.name ?? "fetchme___es",
    path: fetchmepLZwTBxHAeMeta?.path ?? "/es/fetchme",
    meta: fetchmepLZwTBxHAeMeta || {},
    alias: fetchmepLZwTBxHAeMeta?.alias || [],
    redirect: fetchmepLZwTBxHAeMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/fetchme.vue").then(m => m.default || m)
  },
  {
    name: fetchmepLZwTBxHAeMeta?.name ?? "fetchme___fr",
    path: fetchmepLZwTBxHAeMeta?.path ?? "/fr/fetchme",
    meta: fetchmepLZwTBxHAeMeta || {},
    alias: fetchmepLZwTBxHAeMeta?.alias || [],
    redirect: fetchmepLZwTBxHAeMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/fetchme.vue").then(m => m.default || m)
  },
  {
    name: fetchmepLZwTBxHAeMeta?.name ?? "fetchme___zh",
    path: fetchmepLZwTBxHAeMeta?.path ?? "/zh/fetchme",
    meta: fetchmepLZwTBxHAeMeta || {},
    alias: fetchmepLZwTBxHAeMeta?.alias || [],
    redirect: fetchmepLZwTBxHAeMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/fetchme.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___en",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___de",
    path: indexniDiYCWjuTMeta?.path ?? "/de",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___es",
    path: indexniDiYCWjuTMeta?.path ?? "/es",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___fr",
    path: indexniDiYCWjuTMeta?.path ?? "/fr",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___zh",
    path: indexniDiYCWjuTMeta?.path ?? "/zh",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93fKWKWSTdtTMeta?.name ?? "places-dir-slug___en",
    path: _91_46_46_46slug_93fKWKWSTdtTMeta?.path ?? "/places/dir/:slug(.*)*",
    meta: _91_46_46_46slug_93fKWKWSTdtTMeta || {},
    alias: _91_46_46_46slug_93fKWKWSTdtTMeta?.alias || [],
    redirect: _91_46_46_46slug_93fKWKWSTdtTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/places/dir/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93fKWKWSTdtTMeta?.name ?? "places-dir-slug___de",
    path: _91_46_46_46slug_93fKWKWSTdtTMeta?.path ?? "/de/places/dir/:slug(.*)*",
    meta: _91_46_46_46slug_93fKWKWSTdtTMeta || {},
    alias: _91_46_46_46slug_93fKWKWSTdtTMeta?.alias || [],
    redirect: _91_46_46_46slug_93fKWKWSTdtTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/places/dir/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93fKWKWSTdtTMeta?.name ?? "places-dir-slug___es",
    path: _91_46_46_46slug_93fKWKWSTdtTMeta?.path ?? "/es/places/dir/:slug(.*)*",
    meta: _91_46_46_46slug_93fKWKWSTdtTMeta || {},
    alias: _91_46_46_46slug_93fKWKWSTdtTMeta?.alias || [],
    redirect: _91_46_46_46slug_93fKWKWSTdtTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/places/dir/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93fKWKWSTdtTMeta?.name ?? "places-dir-slug___fr",
    path: _91_46_46_46slug_93fKWKWSTdtTMeta?.path ?? "/fr/places/dir/:slug(.*)*",
    meta: _91_46_46_46slug_93fKWKWSTdtTMeta || {},
    alias: _91_46_46_46slug_93fKWKWSTdtTMeta?.alias || [],
    redirect: _91_46_46_46slug_93fKWKWSTdtTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/places/dir/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93fKWKWSTdtTMeta?.name ?? "places-dir-slug___zh",
    path: _91_46_46_46slug_93fKWKWSTdtTMeta?.path ?? "/zh/places/dir/:slug(.*)*",
    meta: _91_46_46_46slug_93fKWKWSTdtTMeta || {},
    alias: _91_46_46_46slug_93fKWKWSTdtTMeta?.alias || [],
    redirect: _91_46_46_46slug_93fKWKWSTdtTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/places/dir/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91state_93I5RXpKladcMeta?.name ?? "places-dir-united-states-state___en",
    path: _91state_93I5RXpKladcMeta?.path ?? "/places/dir/united-states/:state()",
    meta: _91state_93I5RXpKladcMeta || {},
    alias: _91state_93I5RXpKladcMeta?.alias || [],
    redirect: _91state_93I5RXpKladcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/places/dir/united-states/[state].vue").then(m => m.default || m)
  },
  {
    name: _91state_93I5RXpKladcMeta?.name ?? "places-dir-united-states-state___de",
    path: _91state_93I5RXpKladcMeta?.path ?? "/de/places/dir/united-states/:state()",
    meta: _91state_93I5RXpKladcMeta || {},
    alias: _91state_93I5RXpKladcMeta?.alias || [],
    redirect: _91state_93I5RXpKladcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/places/dir/united-states/[state].vue").then(m => m.default || m)
  },
  {
    name: _91state_93I5RXpKladcMeta?.name ?? "places-dir-united-states-state___es",
    path: _91state_93I5RXpKladcMeta?.path ?? "/es/places/dir/united-states/:state()",
    meta: _91state_93I5RXpKladcMeta || {},
    alias: _91state_93I5RXpKladcMeta?.alias || [],
    redirect: _91state_93I5RXpKladcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/places/dir/united-states/[state].vue").then(m => m.default || m)
  },
  {
    name: _91state_93I5RXpKladcMeta?.name ?? "places-dir-united-states-state___fr",
    path: _91state_93I5RXpKladcMeta?.path ?? "/fr/places/dir/united-states/:state()",
    meta: _91state_93I5RXpKladcMeta || {},
    alias: _91state_93I5RXpKladcMeta?.alias || [],
    redirect: _91state_93I5RXpKladcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/places/dir/united-states/[state].vue").then(m => m.default || m)
  },
  {
    name: _91state_93I5RXpKladcMeta?.name ?? "places-dir-united-states-state___zh",
    path: _91state_93I5RXpKladcMeta?.path ?? "/zh/places/dir/united-states/:state()",
    meta: _91state_93I5RXpKladcMeta || {},
    alias: _91state_93I5RXpKladcMeta?.alias || [],
    redirect: _91state_93I5RXpKladcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/places/dir/united-states/[state].vue").then(m => m.default || m)
  }
]